* {
    margin: 0;
    padding: 0;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

body {
    color: $color-black;
    font-family: "Mountains of Christmas", sans-serif;
    font-weight: 700;
    line-height: 1.6;
    font-size: 1.25rem;
    background: $m1;
}

h1 {
    font-size: 2.39rem;
    margin: 0 0 1.25rem 0;
    word-break: break-word;

    @include breakpoint(700px) {
        font-size: 3.1875rem;
    }
}


h2 {
    font-size: 2rem;
    margin: 0 0 1.25rem 0;
}

.App-logo {
    animation: Snuffles-Zoom 6s linear, Snuffles-Spin infinite 4s linear 6s;

    &.string {
        animation: Snuffles-Shake .5s infinite;
    }
}

.btn {
    display: inline-block;
    padding: 12px 36px;
    background: $color-black;
    cursor: pointer;
    border-radius: 8px;
    color: $color-white;
    transition: .3s all;
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.15);
    border: 0;

    &:hover {
        background: darken($color-black, 3%);
        transform: scale(95%);
        box-shadow: 0 0.2em 0.6em rgba(0, 0, 0, 0.25);
    }

    &.fade-in {
        opacity: 0;
        animation: Fade-in 2s;    
        animation-delay: 6s; 
        animation-fill-mode: forwards;
    }
}

a {
    text-decoration: none;
}

.App-header {
    text-align: center;
}

.string-link {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: darken($m1, 5%);
    opacity: .4;
    padding: 5px 20px;
}