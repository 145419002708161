.animal-card {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    transition: all .3s;
    border-radius: 12px;


    &:hover {
      animation: Card-Hover 2s infinite;
    }

    &:not(.flipped) {
      cursor: pointer;
    }
  }
  
  /* This container is needed to position the front and back side */
  .animal-card__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .animal-card.flipped .animal-card__inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .animal-card__front, .animal-card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 12px;
    box-shadow: 0 0.2em 1.2em rgba(255, 166, 0, 0.15);
  }
  
  /* Style the front side (fallback if image is missing) */
  .animal-card__front {
    background-color: #efefef;
    color: black;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
  
  /* Style the back side */
  .animal-card__back {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #efefef;
    color: white;
    transform: rotateY(180deg);
    padding: 5px;

    img {
      max-height: 250px;
      width: 100%;
      object-fit: contain;
    }
  }

  .animal-card__text {

  }