.container {
    max-width: $container-width;
    margin: 0 auto;
    padding: 3.75rem 1.875rem;

    &.grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 1.25rem;
        padding: 1rem 0;

        @include breakpoint(700px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    
        @include breakpoint(1100px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));    
        }

        h1 {
            grid-column: 1 / -1;
            text-align: center;
        }
    }
}