@keyframes Snuffles-Spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes Snuffles-Zoom {
    from {
      transform: scale(10%);
      opacity: 0;
    }
    to {
      transform: scale(100%);
      opacity: 1;
    }
  }

  @keyframes Snuffles-Shake{
    0% {
      transform: scale(100%) rotate(0) translate(0, 0);
    }
    25% {
      transform: scale(60%) rotate(-40deg) translate(-150px, 0);
    }
    50% {
      transform: scale(150%) rotate(20deg) translate(100px, 0);
    }
    75% {
      transform: scale(80%) rotate(20deg) translate(-50px, 0);
    }
    100% {
      transform: scale(100%) rotate(0deg) translate(0, 0);
    }
  }

  @keyframes Card-Hover {
    0% {
      transform: scale(100%);
    }
    50% {
      transform: scale(105%);
    }
    100% {
      transform: scale(100%);
    }
  }

  @keyframes Fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }